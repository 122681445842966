.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
}
.dna-personalized-label {
    background-color: #667F70;
    border-radius: 5px;
    color: white;
    padding: 0px 5px;
    margin: 0px 20px;
}

@font-face 						{ font-family: "Canela Light"; src: url("assets/fonts/Canela-Light.otf"); }
	@font-face 						{ font-family: "GT America Light"; src: url("assets/fonts/GT-America-Light.otf"); }