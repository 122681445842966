td .tdExerciseTitle .thumb			{ width: 50px !important; height: 50px !important; object-fit: cover; border-radius: 10px; margin-right: 10px; } 

.css-13cymwt-control {
    border-color: #979797 !important;
    font-size: 14px;
    color: var(--black);
    padding: 1px 16px;
    border-radius: 20px !important;
}
.css-1p3m7a8-multiValue {
    border-color: #979797 !important;
    font-size: 14px;
    color: var(--black);
    padding: 1px 16px;
    border-radius: 20px !important;
}
.css-1f43avz-a11yText-A11yText {
    border-color: #979797 !important;
    font-size: 14px;
    color: var(--black);
    padding: 1px 16px;
    border-radius: 20px !important;
}
.css-t3ipsp-control:hover {
    border-color: #979797 !important;
    font-size: 14px;
    color: var(--black);
    padding: 1px 16px;
    border-radius: 20px !important;
}
.css-1p3m7a8-multiValue:hover {
    border-color: #979797 !important;
    font-size: 14px;
    color: var(--black);
    padding: 1px 16px;
    border-radius: 20px !important;
}